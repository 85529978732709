import Glide from "@glidejs/glide";

export function initializeCarousel(elementSelector, perView, autoplay = 0, breakpoints = {}, customControl = false) {

    const element = document.querySelector(elementSelector);
    let slidesToDisplay = perView;
    if (element) {
        const slides = element.querySelector('.glide__slides').children;
        const numOfSlides = slides.length;
        slidesToDisplay = numOfSlides > perView ? perView : numOfSlides;
    }

    if (document.querySelector(elementSelector)) {
        const glide = new Glide(elementSelector, {
            type: 'carousel',
            focusAt: 0,
            perView: slidesToDisplay,
            animationDuration: 400,
            gap: 20,
            rewindDuration: 0,
            autoplay: false,
            startAt: 0,
            breakpoints: breakpoints,
        });
        glide.mount();

        if (customControl) {
            const glideArrowLeft = document.querySelector('.glide__arrow-wrap--prev')
            const glideArrowRight = document.querySelector('.glide__arrow-wrap--next')
            glideArrowLeft.addEventListener('click', function () {
                glide.go('<');
            })

            glideArrowRight.addEventListener('click', function () {
                glide.go('>');
            })
        }

    }
}