import { initializeCarousel } from "../components/slider";
document.addEventListener('DOMContentLoaded', () => {


    initializeBootstrap();
    initializeSidebarNav();
    initializeLangNav();
    initializeCarousels();



}, false);

function initializeBootstrap() {
    if (window.bs === undefined || !window.bs) {
        require('bootstrap-sass');
        window.bs = true;
    }
}

function initializeSidebarNav() {
    const body = document.querySelector('.js-body');
    const openSidebarButton = document.querySelector('.js-open-sidebar-button');
    const sidebar = document.querySelector('.js-sidebar');
    const navbarCollapse = sidebar.querySelector('.js-navbar-collapse');
    const slideOut = document.querySelector('.js-slide-out');

    if (body && navbarCollapse && openSidebarButton && sidebar && slideOut) {
        openSidebarButton.addEventListener('click', () => {
            sidebar.style.width = '80%';
            sidebar.classList.add('open');
            setTimeout(() => (navbarCollapse.style.display = 'inline'), 250);
            navbarCollapse.classList.remove('collapse');
            slideOut.classList.add('slide-out--is-open');
        });

        body.addEventListener('click', (event) => {
            if (
                !sidebar.contains(event.target) &&
                !openSidebarButton.contains(event.target) &&
                sidebar.classList.contains('open')
            ) {
                sidebar.style.width = '0';
                navbarCollapse.style.display = 'none';
                slideOut.classList.remove('slide-out--is-open');
            }
        });
    }
}

function initializeLangNav() {
    const navLangLinkMain = document.querySelector('.js-nav-lang-link-main');
    const navLangMenu = document.querySelector('.js-nav-lang-menu');

    if (navLangLinkMain && navLangMenu) {
        navLangLinkMain.addEventListener('click', (e) => {
            e.preventDefault();
            navLangMenu.style.display = 'inline';
        });
    }
}


function initializeCarousels() {
    initializeCarousel('.js-home-carousel-1', 4,5000, {
        1024: {
            perView: 3,
            gap: 10,
        },
        768: {
            perView: 2,
            gap: 10,
        },
        480: {
            perView: 1.5,
            gap: 10,
        },
    });
    initializeCarousel('.js-home-carousel-2', 4,5000, {
        1024: {
            perView: 3,
        },
        768: {
            perView: 2,
        },
        480: {
            perView: 1.5,
        },
    });
    initializeCarousel('.js-product-additional-carousel', 4,0, {
        1200: {
            perView: 3,
        },
        768: {
            perView: 2,
        },
    });
    initializeCarousel('.js-product-additional-mobile-carousel', 4,0, {
        1200: {
            perView: 3,
        },
        768: {
            perView: 2,
        },
    });

    initializeCarousel('.js-product-carousel', 1, 0, {}, true);
}

